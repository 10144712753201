// File: frontend/src/components/settings/ResponseSettings.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { 
  getResponseSettings, 
  getCategoryResponseSettings, 
  updateResponseSettings, 
  updateCategoryResponseSettings, 
  clearAllCategoryResponseSettings, 
  getEmailCategories 
} from '../../services/adminApi';
import { runDetermineWritingStyle, getTaskStatus } from '../../services/agentApi'; // Updated to include getTaskStatus
import DOMPurify from 'dompurify';
import { ResponseSettingsData, ResponseSettingsProps, CategoryResponseSettings } from '../../types/settings';
import { useToast } from '../../context/ToastContext';

interface ExtendedCategoryResponseSettings extends CategoryResponseSettings {
  id?: number | null;
  user_id: number;
}

interface CategoryData {
  category: string;
  tone_style: string;
  jargon_usage: string;
  example_response: string;
}

const defaultSettings: ResponseSettingsData = {
  responseLength: '',
  structurePreferences: [],
  proposeMeetings: false,
  meetingTimeframe: '',
  includeCalendarLink: false,
  greetingOptions: [],
  closingOptions: [],
  signature: '',
  categories: []
};

const ResponseSettings: React.FC<ResponseSettingsProps> = ({ userId }) => {
  const [settings, setSettings] = useState<ResponseSettingsData>(defaultSettings);
  const [categorySettings, setCategorySettings] = useState<ExtendedCategoryResponseSettings[]>([]);
  const [allCategories, setAllCategories] = useState<{ name: string; response_required: boolean }[]>([]);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { showToast } = useToast();

  // New state variables for asynchronous task handling
  const [taskId, setTaskId] = useState<string | null>(null);
  const [taskStatus, setTaskStatus] = useState<string | null>(null);
  const [taskError, setTaskError] = useState<string | null>(null);  // Add this line

  const responseLengthOptions = ['Very Brief (1 paragraph)', 'Brief (2-3 paragraphs)', 'Moderate (4-5 paragraphs)', 'Detailed (6+ paragraphs)'];
  const structureOptions = ['Use bullet points', 'Use numbered lists', 'Use paragraphs only'];
  const meetingTimeframeOptions = ['Today', 'Tomorrow', 'This week', 'Next week', 'This month', 'Custom'];

  /**
   * Normalize the general settings data fetched from the backend.
   */
  const normalizeData = (data: any): ResponseSettingsData => {
    return {
      responseLength: data.response_length || '',
      structurePreferences: data.structure_preferences || [],
      proposeMeetings: data.propose_meetings || false,
      meetingTimeframe: data.meeting_timeframe || '',
      includeCalendarLink: data.include_calendar_link || false,
      greetingOptions: data.greeting_options || [],
      closingOptions: data.closing_options || [],
      signature: data.signature || '',
      categories: data.categories || []
    };
  };

  /**
   * Merge category data fetched from the backend with existing category settings.
   */
  const mergeCategoryData = useCallback((
    categories: { name: string; response_required: boolean }[], 
    categorySettings: ExtendedCategoryResponseSettings[]
  ): ExtendedCategoryResponseSettings[] => {
    return categories
      .filter(category => category.response_required) // Only include categories that require a response
      .map(category => {
        const existingSettings = categorySettings.find(cs => cs.category === category.name);
        return {
          category: category.name,
          tone_style: existingSettings?.tone_style || '',
          jargon_usage: existingSettings?.jargon_usage || '',
          example_response: existingSettings?.example_response || '',
          id: existingSettings?.id || null,
          user_id: userId
        };
      });
  }, [userId]);

  /**
   * Fetch initial settings from the backend.
   */
  const fetchSettings = useCallback(async () => {
    try {
      setIsLoading(true);
      const [generalData, categoryData, allCategoriesData] = await Promise.all([
        getResponseSettings(userId),
        getCategoryResponseSettings(userId),
        getEmailCategories(userId)
      ]);

      const normalizedGeneralData = normalizeData(generalData);
      setSettings(normalizedGeneralData);

      const responseRequiredCategories = allCategoriesData.filter(cat => cat.response_required);
      setAllCategories(responseRequiredCategories);

      const mergedCategorySettings = mergeCategoryData(responseRequiredCategories, categoryData as ExtendedCategoryResponseSettings[]);
      setCategorySettings(mergedCategorySettings);

      setError(null);
      showToast('Response settings loaded successfully', 'success');
    } catch (err) {
      console.error('Error fetching response settings:', err);
      setError('Failed to fetch response settings. Please try again later.');
      setSettings(defaultSettings);
      showToast('Failed to load response settings', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [userId, showToast, mergeCategoryData]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  /**
   * Handle input changes for general settings.
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox') {
      // Type assertion to HTMLInputElement to access 'checked'
      const target = e.target as HTMLInputElement;
      setSettings(prev => ({ ...prev, [name]: target.checked }));
    } else {
      setSettings(prev => ({ ...prev, [name]: value }));
    }
  };

  /**
   * Handle changes in category-specific settings.
   */
  const handleCategoryChange = (categoryName: string, field: keyof CategoryResponseSettings, value: string) => {
    setCategorySettings(prev =>
      prev.map(category =>
        category.category === categoryName
          ? { ...category, [field]: value }
          : category
      )
    );
  };

  /**
   * Submit updates for a specific category.
   */
  const handleCategorySubmit = async (categoryName: string) => {
    const categoryData = categorySettings.find(c => c.category === categoryName);
    if (categoryData) {
      try {
        setIsLoading(true);
        await updateCategoryResponseSettings(userId, categoryName, categoryData);
        setError(null);
        showToast(`Category settings for "${categoryName}" updated successfully`, 'success');
      } catch (err) {
        console.error('Error updating category response settings:', err);
        setError('Failed to update category response settings.');
        showToast(`Failed to update category settings for "${categoryName}"`, 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };

  /**
   * Toggle the expansion of a category's settings.
   */
  const toggleCategory = (categoryName: string) => {
    setExpandedCategory(expandedCategory === categoryName ? null : categoryName);
  };

  /**
   * Submit updates for general settings.
   */
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await updateResponseSettings(userId, settings);
      showToast('Settings updated successfully', 'success');
    } catch (error) {
      console.error('Error updating settings:', error);
      showToast('Error updating settings. Please try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handle changes in structure preferences.
   */
  const handleStructurePreferenceChange = (preference: string) => {
    setSettings((prev) => {
      const updatedPreferences = prev.structurePreferences.includes(preference)
        ? prev.structurePreferences.filter(p => p !== preference) 
        : [...prev.structurePreferences, preference]; 
      return { ...prev, structurePreferences: updatedPreferences };
    });
    showToast(`Structure preference "${preference}" ${settings.structurePreferences.includes(preference) ? 'removed' : 'added'}`, 'info');
  };

  /**
   * Handler to run the Determine Writing Style agent asynchronously via Celery.
   */
  const handleRunDetermineWritingStyle = async () => {
    if (!window.confirm("This will scan your last 20 sent emails and determine your writing style. Do you want to proceed?")) {
      return;
    }
    try {
      setIsLoading(true);
      // Enqueue the Celery task
      const response = await runDetermineWritingStyle(userId);
      console.log('Task Enqueue Response:', response); // Added for debugging
      setTaskId(response.task_id);
      setTaskStatus(response.status); // Typically "Queued" or "Pending"
      showToast('Determine Writing Style agent started.', 'info');
      setError(null);
    } catch (err: any) {
      console.error('Error enqueueing Determine Writing Style agent:', err);
      setError(err.message || 'Failed to enqueue Determine Writing Style agent.');
      showToast('Failed to start Determine Writing Style agent.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Polling effect to check the status of the Celery task.
   */
  useEffect(() => {
    let interval: NodeJS.Timeout;

    const fetchTaskStatus = async (currentTaskId: string) => {
      try {
        const statusResponse = await getTaskStatus(currentTaskId);
        console.log('Task Status Response:', statusResponse);

        setTaskStatus(statusResponse.status);

        if (statusResponse.status === 'Completed') {
          if (statusResponse.data) {
            const { categories, general_settings } = statusResponse.data;
            
            // Update general settings
            setSettings(prev => ({
              ...prev,
              responseLength: general_settings.response_length_options[0] || prev.responseLength,
              structurePreferences: general_settings.structure_options || prev.structurePreferences,
              greetingOptions: general_settings.greeting_options || prev.greetingOptions,
              closingOptions: general_settings.closing_options || prev.closingOptions
            }));

            // Update category-specific settings
            setCategorySettings(prevSettings => {
              return prevSettings.map(prevCat => {
                const updatedCat = (categories as CategoryData[]).find(cat => cat.category === prevCat.category);
                if (updatedCat) {
                  return {
                    ...prevCat,
                    tone_style: updatedCat.tone_style || prevCat.tone_style,
                    jargon_usage: updatedCat.jargon_usage || prevCat.jargon_usage,
                    example_response: updatedCat.example_response || prevCat.example_response
                  };
                }
                return prevCat;
              });
            });

            showToast('Determine Writing Style agent completed successfully.', 'success');
          } else {
            setError('Completed task has no data.');
            showToast('Completed task has no data.', 'error');
          }
          clearInterval(interval);
        } else if (statusResponse.status === 'Failed' || statusResponse.status === 'FAILURE') {
          setTaskError(statusResponse.detail || 'Determine Writing Style agent failed.');
          setError(statusResponse.detail || 'Determine Writing Style agent failed.');
          showToast('Determine Writing Style agent failed.', 'error');
          clearInterval(interval);
        }
        // If the status is 'Processing' or 'Pending', we don't need to do anything special
      } catch (err: any) {
        console.error('Error fetching task status:', err);
        setTaskError('Failed to fetch task status.');
        setError('Failed to fetch task status.');
        showToast('Failed to fetch task status.', 'error');
        clearInterval(interval);
      }
    };

    // Start polling only if there's a task ID and the task is not completed or failed
    if (taskId && taskStatus !== 'Completed' && taskStatus !== 'Failed' && taskStatus !== 'FAILURE') {
      // Start polling every 5 seconds
      interval = setInterval(() => {
        fetchTaskStatus(taskId);
      }, 5000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [taskId, taskStatus, showToast, userId]);

  /**
   * Handler to clear all category settings.
   */
  const handleClearAllCategorySettings = async () => {
    if (window.confirm("Are you sure you want to clear all category settings? This action cannot be undone.")) {
      try {
        setIsLoading(true);
        await clearAllCategoryResponseSettings(userId);
        // After clearing, reset all category settings to their default state
        const resetCategorySettings = allCategories.map(category => ({
          category: category.name,
          tone_style: '',
          jargon_usage: '',
          example_response: '',
          id: null,
          user_id: userId
        }));
        setCategorySettings(resetCategorySettings);
        showToast('All category settings cleared successfully', 'success');
      } catch (err) {
        console.error('Error clearing category response settings:', err);
        showToast('Failed to clear category response settings', 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };

  /**
   * Loading spinner implementation.
   */
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col items-center">
          <div className="flex justify-center items-center">
            <span className="loading loading-spinner loading-lg"></span>
          </div>
          <p className="text-gray-700 mt-4 text-center">
            Do not navigate away from this page while awaiting the response from the determine writing agent/process.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Header and Button Container */}
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800">Response Settings</h2>
        {/* Run Determine Writing Style Button */}
        <button
          type="button"
          onClick={handleRunDetermineWritingStyle}
          className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-300"
          disabled={taskStatus === 'Processing' || taskStatus === 'Queued'}
        >
          {taskStatus === 'Processing' || taskStatus === 'Queued' ? 'Running...' : 'Run Determine Writing Style'}
        </button>
      </div>
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      {/* Display Task Status and Data */}
      {taskId && (
        <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
          <p className="font-semibold">Determine Writing Style Task</p>
          <p>Status: {taskStatus}</p>
          {taskStatus === 'Completed' && (
            <p className="mt-2">Task completed successfully.</p>
          )}
          {(taskStatus === 'Failed' || taskStatus === 'FAILURE') && (
            <p className="mt-2">Detail: {taskError || 'An error occurred.'}</p>
          )}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Category-specific settings */}
        {categorySettings.length > 0 && (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-gray-800">Category-Specific Settings</h3>
            {categorySettings.map((category) => (
              <div key={category.category} className="border border-gray-200 rounded-md overflow-hidden">
                <div
                  className="bg-gray-50 px-4 py-3 cursor-pointer flex justify-between items-center"
                  onClick={() => toggleCategory(category.category)}
                >
                  <h4 className="text-lg font-medium text-gray-700">{category.category}</h4>
                  <span>{expandedCategory === category.category ? '−' : '+'}</span>
                </div>
                {expandedCategory === category.category && (
                  <div className="p-4 space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Tone and Style</label>
                        <select
                          value={category.tone_style || ''}
                          onChange={(e) => handleCategoryChange(category.category, 'tone_style', e.target.value)}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        >
                          <option value="">Select tone</option>
                          <option value="Formal and concise">Formal and concise</option>
                          <option value="Casual and friendly">Casual and friendly</option>
                          <option value="Friendly and enthusiastic">Friendly and enthusiastic</option>
                          <option value="Collaborative and innovative">Collaborative and innovative</option>
                          <option value="Persuasive and passionate">Persuasive and passionate</option>
                          <option value="Assertive and respectful">Assertive and respectful</option>
                          <option value="Empathetic and supportive">Empathetic and supportive</option>
                          <option value="Diplomatic and firm">Diplomatic and firm</option>
                          <option value="Confident and humble">Confident and humble</option>
                          <option value="Supportive and practical">Supportive and practical</option>
                          <option value="Direct and considerate">Direct and considerate</option>
                          <option value="Optimistic and realistic">Optimistic and realistic</option>
                          <option value="Analytical and insightful">Analytical and insightful</option>
                          <option value="Instructive and patient">Instructive and patient</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Jargon Usage</label>
                        <select
                          value={category.jargon_usage || ''}
                          onChange={(e) => handleCategoryChange(category.category, 'jargon_usage', e.target.value)}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        >
                          <option value="">Select jargon level</option>
                          <option value="None">None</option>
                          <option value="Low">Low</option>
                          <option value="Moderate">Moderate</option>
                          <option value="High">High</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Example Response</label>
                      <textarea
                        value={category.example_response || ''}
                        onChange={(e) => handleCategoryChange(category.category, 'example_response', e.target.value)}
                        rows={3}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => handleCategorySubmit(category.category)}
                      className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
                    >
                      Save {category.category} Settings
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {/* General Response Settings */}
        <div className="space-y-6">
          <h3 className="text-xl font-semibold text-gray-800">General Response Settings</h3>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Response Length Preference</label>
            <select
              name="responseLength"
              value={settings.responseLength}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Select response length</option>
              {responseLengthOptions.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Structure Preferences</label>
            <div className="space-y-2">
              {structureOptions.map(option => (
                <label key={option} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={settings.structurePreferences.includes(option)}
                    onChange={() => handleStructurePreferenceChange(option)}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <span className="ml-2 text-sm text-gray-900">{option}</span>
                </label>
              ))}
            </div>
          </div>

          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="proposeMeetings"
                checked={settings.proposeMeetings}
                onChange={handleInputChange}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <span className="ml-2 text-sm text-gray-900">Propose meetings</span>
            </label>
          </div>

          {settings.proposeMeetings && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Meeting Timeframe</label>
              <select
                name="meetingTimeframe"
                value={settings.meetingTimeframe}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Select timeframe</option>
                {meetingTimeframeOptions.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
          )}
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Greeting Options</label>
            <input
              type="text"
              name="greetingOptions"
              value={settings.greetingOptions.join(', ')}
              onChange={(e) => setSettings(prev => ({ 
                ...prev, 
                greetingOptions: e.target.value.split(',').map(opt => opt.trim()).filter(opt => opt) 
              }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter greeting options, separated by commas"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Closing Options</label>
            <input
              type="text"
              name="closingOptions"
              value={settings.closingOptions.join(', ')}
              onChange={(e) => setSettings(prev => ({ 
                ...prev, 
                closingOptions: e.target.value.split(',').map(opt => opt.trim()).filter(opt => opt) 
              }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter closing options, separated by commas"
            />
          </div>

          <div>
            <label htmlFor="signature" className="block text-sm font-medium text-gray-700 mb-1">
              Email Signature (HTML)
            </label>
            <textarea
              id="signature"
              name="signature"
              value={settings.signature}
              onChange={handleInputChange}
              rows={6}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter your email signature HTML here"
            />
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">Signature Preview</label>
              <div
                className="mt-1 p-4 border rounded-md bg-white"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(settings.signature) }}
              />
            </div>
          </div>
        </div>

        <div className="pt-5 border-t border-gray-200">
          <div className="flex justify-between items-center">
            <button
              type="button"
              onClick={handleClearAllCategorySettings}
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-300"
            >
              Clear All Category Settings
            </button>
            <div className="flex space-x-4">
              <button
                type="submit"
                className="px-4 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-md hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
              >
                Save All Changes
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResponseSettings;